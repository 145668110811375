import { useStorage } from '@vueuse/core'

// Local storage
const deliveryLocation = useStorage<string>('shop:delivery-location', '')
const isSelfCollection = useStorage<boolean>('shop:is-self-collection', false)
const rentalStart = useStorage<number | undefined>('shop:rental-start', undefined)
const rentalEnd = useStorage<number | undefined>('shop:rental-end', undefined)

export default () => {
  const rentalTimeFrame = computed<[number, number] | undefined>(() => {
    if (!rentalStart.value || !rentalEnd.value) {
      return undefined
    }
    return [Number(rentalStart.value), Number(rentalEnd.value)]
  })

  function updateRentalTimeFrame(timeframe: [number, number] | undefined) {
    if (!timeframe) {
      rentalStart.value = undefined
      rentalEnd.value = undefined
      return
    }
    rentalStart.value = timeframe[0]
    rentalEnd.value = timeframe[1]
  }

  // Shopping cart
  const cartId = useCookie<string | undefined>('shop:cartId', undefined)

  return {
    rentalTimeFrame,
    updateRentalTimeFrame,
    deliveryLocation,
    isSelfCollection,
    cartId,
    rentalTime: readonly({
      startDate: rentalStart,
      endDate: rentalEnd,
    }),
  }
}
